import React, { useState, useEffect } from "react";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import {
  BottomNavigation,
  BottomNavigationAction,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  MenuItem,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import HomeIcon from "@material-ui/icons/Home";
import ListIcon from "@material-ui/icons/List";
import AddIcon from "@material-ui/icons/Add";
import PersonIcon from "@material-ui/icons/Person";
import AccountCircle from "@material-ui/icons/AccountCircle";

import {
  isMobileDevice,
  isTabletDevice,
} from 'typed-responsive-react';

import Home from "./components/scenes/Home";
import HomeAdmin from "./components/scenes/HomeAdmin";
import UsersAdmin from "./components/scenes/UsersAdmin";
import Registrations from "./components/scenes/Registrations";
import RegistrationsInCards from "./components/scenes/RegistrationsInCards";
import NewRegistration from "./components/scenes/NewRegistration";
import Profile from "./components/scenes/Profile";
import Login from "./components/scenes/Login";
import RegistrationsAdmin from "./components/scenes/RegistrationsAdmin";

import AuthHelperMethods from "./util/AuthHelperMethods";
import { getCardViewToggle } from "./util/FeatureToggleMethods";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

function App(props: any) {
  const [page, setPage] = useState(0);
  const classes = useStyles();
  const [auth, setAuth] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleMenu = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logout = () => {
    Auth.logout();
    handleClose();
    setAuth(false);
    window.location.href = "/";
  };

  const Auth: any = new AuthHelperMethods();

  useEffect(() => {
    if (!Auth.loggedIn()) {
      setAuth(false);
    }
  }, [auth, Auth]);

  const confirm = Auth.getConfirm();

  const getRoutes = () => {
    switch (page) {
      case 1:
        return confirm && confirm.level === "ADMIN" ? (
          <RegistrationsAdmin />
        ) : (
            ((isMobileDevice() || isTabletDevice()) && getCardViewToggle()) ? <RegistrationsInCards /> : <Registrations />
          );
      case 2:
        return confirm && confirm.level === "ADMIN" ? (
          <UsersAdmin />
        ) : (
            <NewRegistration />
          );
      case 3:
        return <Profile />;
      default:
        return confirm && confirm.level === "ADMIN" ? <HomeAdmin /> : <Home />;
    }
  };

  return (
    <Router>
      <div className="App">
        <div className="header">
          <AppBar position="fixed">
            <Toolbar>
              <Typography variant="h6" className={classes.title}>
                Tijdsregistratie{" "}
                {confirm && confirm.level === "ADMIN" ? "- BEHEER" : ""}
              </Typography>
              {auth && (
                <div>
                  <IconButton
                    aria-label="account"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleMenu}
                    color="inherit"
                  >
                    <AccountCircle />
                  </IconButton>
                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    open={open}
                    onClose={handleClose}
                  >
                    <MenuItem
                      onClick={() => {
                        setPage(3);
                        handleClose();
                      }}
                    >
                      Profiel
                    </MenuItem>
                    <MenuItem onClick={logout}>Uitloggen</MenuItem>
                  </Menu>
                </div>
              )}
            </Toolbar>
          </AppBar>
        </div>
        <div className="mainContent">
          <Switch>
            <Route path="/login">
              <Login />
            </Route>
            <Route path="/">{getRoutes()}</Route>
          </Switch>
        </div>
        {auth && confirm && confirm.level === "USER" && (
          <div className="footer">
            <BottomNavigation
              value={page}
              onChange={(_, newValue) => {
                setPage(newValue);
              }}
              showLabels
            >
              <BottomNavigationAction label="Startpagina" icon={<HomeIcon />} />
              <BottomNavigationAction
                label="Registraties"
                icon={<ListIcon />}
              />
              <BottomNavigationAction label="Toevoegen" icon={<AddIcon />} />
            </BottomNavigation>
          </div>
        )}
        {auth && confirm && confirm.level === "ADMIN" && (
          <div className="footer">
            <BottomNavigation
              value={page}
              onChange={(_, newValue) => {
                setPage(newValue);
              }}
              showLabels
            >
              <BottomNavigationAction label="Startpagina" icon={<HomeIcon />} />
              <BottomNavigationAction
                label="Registraties"
                icon={<ListIcon />}
              />
              <BottomNavigationAction
                label="Gebruikers"
                icon={<PersonIcon />}
              />
            </BottomNavigation>
          </div>
        )}
      </div>
    </Router>
  );
}

export default App;
