import React, { useState } from "react";

import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import SaveIcon from "@material-ui/icons/Save";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { Snackbar, CircularProgress } from "@material-ui/core";
import { ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import AuthHelperMethods from "../../util/AuthHelperMethods";
import { patchUser } from "../../services/userService";
import { getCardViewToggle, setCardViewToggle } from "../../util/FeatureToggleMethods";
import { reportToSentry } from "../../util/SentryHelperMethods";

import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { DatePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import "moment/locale/nl";
moment.locale("nl");

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}));

export default function Profile() {
  const classes = useStyles();
  const Auth: any = new AuthHelperMethods();
  const [firstName, setFirstName] = useState(Auth.getConfirm().firstName);
  const [lastName, setLastName] = useState(Auth.getConfirm().lastName);
  const [email, setEmail] = useState(Auth.getConfirm().email);
  const [iban, setIban] = useState(Auth.getConfirm().iban);
  const [password, setPassword] = useState("");
  const [badgeNumber, setBadgeNumber] = useState(Auth.getConfirm().badgeNumber);
  const [badgeExpiration, setBadgeExpiration] = useState(Auth.getConfirm().badgeExpiration);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [saving, setSaving] = useState(false);
  const [cardView, setCardView] = useState(getCardViewToggle());

  const saveProfile = async () => {
    setSaving(true);
    patchUser(firstName, lastName, email, iban, password, badgeNumber, badgeExpiration)
      .then(response => response.json())
      .then(data => {
        Auth.setToken(data.token);
        setSnackbarOpen(true);
        setSaving(false);
      })
      .catch(err => console.error(err));
  };

  const canSave = (): boolean => {
    return (
      firstName === "" ||
      lastName === "" ||
      password !== confirmPassword ||
      badgeNumber === "" ||
      badgeExpiration === null
    );
  };

  const handleCardViewToggle = (event: any) => {
    reportToSentry(new Error("Someone changed the cardViewToggle"));
    setCardViewToggle(event.target.checked);
    setCardView(event.target.checked);
  }

  return (
    <Container component="main" maxWidth="lg">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <div style={{ marginBottom: "30px" }}>
          <Typography component="h1" variant="h5">
            Profiel en instellingen: {Auth.getConfirm().username}
          </Typography>
        </div>
        <ExpansionPanel style={{ marginBottom: "15px" }} defaultExpanded={true}>
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Profiel</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="fname"
                  name="firstName"
                  variant="outlined"
                  required
                  fullWidth
                  id="firstName"
                  label="Voornaam"
                  value={firstName}
                  onChange={event => setFirstName(event.target.value)}
                  autoFocus
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="lastName"
                  label="Achternaam"
                  value={lastName}
                  onChange={event => setLastName(event.target.value)}
                  name="lastName"
                  autoComplete="lname"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  fullWidth
                  id="email"
                  label="Email adres"
                  value={email}
                  onChange={event => setEmail(event.target.value)}
                  name="email"
                  autoComplete="email"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  fullWidth
                  name="iban"
                  label="IBAN-nummer"
                  value={iban}
                  onChange={event => setIban(event.target.value)}
                  type="text"
                  id="iban"
                  autoComplete="iban"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="badgeNumber"
                  variant="outlined"
                  required
                  fullWidth
                  id="badgeNumber"
                  label="Badge nummer"
                  value={badgeNumber}
                  onChange={(event) => setBadgeNumber(event.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <MuiPickersUtilsProvider
                  utils={MomentUtils}
                  libInstance={moment}
                  locale="nl"
                >
                  <DatePicker
                    label="Vervaldatum"
                    inputVariant="outlined"
                    value={badgeExpiration}
                    onChange={(value) => setBadgeExpiration(value)}
                    format="DD/MM/YYYY"
                    style={{ width: "100%" }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  fullWidth
                  name="password"
                  label="Wachtwoord"
                  value={password}
                  onChange={event => setPassword(event.target.value)}
                  type="password"
                  id="password"
                  autoComplete="current-password"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  fullWidth
                  name="confirm-password"
                  label="Bevestig wachtwoord"
                  value={confirmPassword}
                  onChange={event => setConfirmPassword(event.target.value)}
                  type="password"
                  id="confirm-password"
                  autoComplete="confirm-password"
                />
              </Grid>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                startIcon={saving ? null : <SaveIcon />}
                onClick={() => saveProfile()}
                disabled={canSave()}
              >
                {saving ? <CircularProgress color="inherit" size={20} /> : "Opslaan"}
              </Button>
            </Grid>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel style={{ marginBottom: "15px" }}>
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Instellingen</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Switch
                    checked={cardView}
                    onChange={handleCardViewToggle}
                    name="cardViewToggle"
                    color="primary"
                  />
                }
                label="Registraties in cards weergeven op een mobiel toestel"
              />
            </FormGroup>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </div>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        autoHideDuration={6000}
        message="Opgeslagen!"
      />
    </Container>
  );
}
