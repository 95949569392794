import React from "react";
import Button from "@material-ui/core/Button";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import moment from "moment";
import "moment/locale/nl";
import ExportPopoverContent from "./ExportPopoverContent";
import ExportPopoverContentAdmin from "./ExportPopoverContentAdmin";
import AuthHelperMethods from "../util/AuthHelperMethods";
moment.locale("nl");

export default function ExportPopover() {
  const Auth: any = new AuthHelperMethods();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        size="large"
        startIcon={<CloudDownloadIcon />}
        fullWidth={true}
        onClick={handleClickOpen}
      >
        Genereer onkostennota
      </Button>
      {Auth.getConfirm().level === "USER" &&
        <ExportPopoverContent open={open} handleClose={handleClose} />
      }
      {Auth.getConfirm().level === "ADMIN" &&
        <ExportPopoverContentAdmin open={open} handleClose={handleClose} />
      }
    </div>
  );
}
