import React, { useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import SaveIcon from "@material-ui/icons/Save";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { patchOtherUser, removeUser } from "../../services/userService";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import DeleteIcon from "@material-ui/icons/Delete";
import {
  Snackbar,
  CircularProgress,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { DatePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import "moment/locale/nl";
moment.locale("nl");

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const EditUser = (props: any) => {
  const classes = useStyles();
  const [firstName, setFirstName] = useState(props.user.firstName);
  const [lastName, setLastName] = useState(props.user.lastName);
  const [email, setEmail] = useState(props.user.email);
  const [iban, setIban] = useState(props.user.iban);
  const [level, setLevel] = useState(props.user.level);
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState(props.user.username);
  const [badgeNumber, setBadgeNumber] = useState(props.user.badgeNumber);
  const [badgeExpiration, setBadgeExpiration] = useState(props.user.badgeExpiration);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [saving, setSaving] = useState(false);
  const [removeDialogOpen, setRemoveDialogOpen] = useState(false);

  const saveProfile = async () => {
    setSaving(true);
    patchOtherUser(
      props.user._id,
      firstName,
      lastName,
      email,
      iban,
      password,
      level,
      username,
      badgeNumber,
      badgeExpiration
    )
      .then(() => {
        setSnackbarOpen(true);
        setSaving(false);
      })
      .catch((err: string) => console.error(err));
  };

  const deleteUser = async () => {
    removeUser(props.user._id).then(() => props.back());
  };

  const canSave = (): boolean => {
    return (
      firstName === "" ||
      lastName === "" ||
      level === "" ||
      username === "" ||
      badgeNumber === "" ||
      badgeExpiration === null
    );
  };

  return (
    <Container component="main" maxWidth="lg">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <div style={{ marginBottom: "30px" }}>
          <Typography component="h1" variant="h5">
            Profiel aanpassen: {username}
          </Typography>
        </div>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <TextField
              autoComplete="username"
              name="username"
              variant="outlined"
              required
              fullWidth
              id="username"
              label="Gerbruikersnaam"
              value={username}
              onChange={(event) => setUsername(event.target.value)}
              autoFocus
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              autoComplete="fname"
              name="firstName"
              variant="outlined"
              required
              fullWidth
              id="firstName"
              label="Voornaam"
              value={firstName}
              onChange={(event) => setFirstName(event.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="outlined"
              required
              fullWidth
              id="lastName"
              label="Achternaam"
              value={lastName}
              onChange={(event) => setLastName(event.target.value)}
              name="lastName"
              autoComplete="lname"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              fullWidth
              id="email"
              label="Email adres"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              name="email"
              autoComplete="email"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              fullWidth
              name="iban"
              label="IBAN-nummer"
              value={iban}
              onChange={(event) => setIban(event.target.value)}
              type="text"
              id="iban"
              autoComplete="iban"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="badgeNumber"
              variant="outlined"
              required
              fullWidth
              id="badgeNumber"
              label="Badge nummer"
              value={badgeNumber}
              onChange={(event) => setBadgeNumber(event.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MuiPickersUtilsProvider
              utils={MomentUtils}
              libInstance={moment}
              locale="nl"
            >
              <DatePicker
                label="Vervaldatum"
                inputVariant="outlined"
                value={badgeExpiration}
                onChange={(value) => setBadgeExpiration(value)}
                format="DD/MM/YYYY"
                style={{ width: "100%" }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12}>
            <FormControl variant="outlined" style={{ width: "100%" }}>
              <InputLabel id="role">Rol *</InputLabel>
              <Select
                required
                labelId="role"
                value={level}
                onChange={(event) => setLevel(event.target.value)}
                label="Role"
                style={{ width: "100%" }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value="USER">Gebruiker</MenuItem>
                <MenuItem value="ADMIN">Beheerder</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              fullWidth
              name="password"
              label="Wachtwoord"
              value={password}
              onChange={(event) => setPassword(event.target.value)}
              type="password"
              id="password"
              autoComplete="current-password"
            />
          </Grid>
        </Grid>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          startIcon={saving ? null : <SaveIcon />}
          onClick={() => saveProfile()}
          disabled={canSave()}
        >
          {saving ? <CircularProgress color="inherit" size={20} /> : "Opslaan"}
        </Button>
        <Button
          fullWidth
          variant="outlined"
          color="secondary"
          className={classes.submit}
          startIcon={<DeleteIcon />}
          onClick={() => setRemoveDialogOpen(true)}
        >
          Verwijderen
        </Button>
        <Button
          fullWidth
          variant="outlined"
          color="primary"
          className={classes.submit}
          startIcon={<ArrowBackIcon />}
          onClick={() => props.back()}
        >
          Terug
        </Button>
      </div>
      <Dialog
        open={removeDialogOpen}
        onClose={() => setRemoveDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Ben je zeker?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Ben je zeker dat je deze gebruiker wilt verwijderen? Alle
            registraties van deze gebruiker zullen bijgevolg ook verwijderd
            worden.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setRemoveDialogOpen(false)} color="primary">
            Neen
          </Button>
          <Button onClick={() => deleteUser()} color="primary" autoFocus>
            Verwijderen
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        autoHideDuration={6000}
        message="Profiel opgeslagen!"
      />
    </Container>
  );
};

export default EditUser;
