import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  box: {
    border: "1px solid lightgray",
    width: "50px",
    padding: "10px",
    textAlign: "center",
    fontWeight: "bold"
  }
}));

const NumberBox = (props: any) => {
  const classes = useStyles();
  return <div className={classes.box}>{props.label}</div>;
};

export default NumberBox;
