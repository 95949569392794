import React, { useEffect, useState } from "react";
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
  Container,
  Typography,
  Button,
} from "@material-ui/core";
import withAuth from "../auth/withAuth";
import { getUsers } from "../../services/userService";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import EditUserScreen from "./EditUser";
import NewUserScreen from "./NewUser";
import AddIcon from "@material-ui/icons/PersonAdd";

const UsersAdmin = function UsersAdmin() {
  const [users, setUsers]: [any, any] = useState(null);
  const [activeUser, setActiveUser]: [any, any] = useState(null);
  const [addUser, setAddUser]: [any, any] = useState(false);

  useEffect(() => {
    setData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setData = async () => {
    setUsers(null);
    const data = (await getUsers()).sort((u1, u2) => {
      if (u1.username < u2.username) { return -1; }
      if (u1.username > u2.username) { return 1; }
      return 0;
    });
    setUsers(data);
  };

  const back = () => {
    setUsers(null);
    setData();
    setActiveUser(null);
    setAddUser(null);
  };

  if (users) {
    if (activeUser) {
      return <EditUserScreen user={activeUser} back={back} />;
    } else if (addUser) {
      return <NewUserScreen back={back} />;
    } else {
      if (users.length > 0) {
        return (
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <Container component="main" maxWidth="lg">
              <div style={{ textAlign: "center", marginBottom: "30px" }}>
                <Typography component="h1" variant="h4">
                  Gebruikers
                </Typography>
              </div>
              <div style={{ textAlign: "right", marginBottom: "20px" }}>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<AddIcon />}
                  onClick={() => setAddUser(true)}
                >
                  Nieuwe gebruiker
                </Button>
              </div>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Gebruikersnaam</TableCell>
                      <TableCell>Voornaam</TableCell>
                      <TableCell>Achternaam</TableCell>
                      <TableCell>E-mail adres</TableCell>
                      <TableCell>Type</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {users.map((row: any) => (
                      <TableRow
                        hover
                        style={{ cursor: "pointer" }}
                        key={row._id}
                        onClick={() => setActiveUser(row)}
                      >
                        <TableCell>{row.username}</TableCell>
                        <TableCell>{row.firstName}</TableCell>
                        <TableCell>{row.lastName}</TableCell>
                        <TableCell>{row.email}</TableCell>
                        <TableCell>
                          {row.level === "ADMIN" ? "Beheerder" : "Gebruiker"}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Container>
          </MuiPickersUtilsProvider>
        );
      } else {
        return <p>Geen gebruikers gevonden!</p>;
      }
    }
  } else {
    return (
      <div style={{ textAlign: "center", marginTop: "20%" }}>
        <CircularProgress color="inherit" />
      </div>
    );
  }
};

export default withAuth(UsersAdmin);
