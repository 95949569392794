import * as Sentry from "@sentry/browser";
import AuthHelperMethods from "./AuthHelperMethods";

export const reportToSentry = (error: any) => {
  const Auth: any = new AuthHelperMethods();
  Sentry.withScope((scope) => {
    scope.setUser(Auth.getConfirm());
    Sentry.captureException(error);
  });
};
