import decode from "jwt-decode";
import { reportToSentry } from "../util/SentryHelperMethods";

export default class AuthHelperMethods {
  login = (username: string, password: string) => {
    return this.fetch(`/api/login`, {
      method: "POST",
      body: JSON.stringify({
        username,
        password,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.token) {
          this.setToken(res.token);
        }
        return Promise.resolve(res);
      })
      .catch((err) => reportToSentry(err));
  };

  loggedIn = () => {
    const token = this.getToken();
    return !!token && !this.isTokenExpired(token);
  };

  isTokenExpired = (token: string) => {
    try {
      const decoded: any = decode(token);
      if (decoded.exp < Date.now() / 1000) {
        return true;
      } else {
        return false;
      }
    } catch (err) {
      reportToSentry(err);
      // console.log("expired check failed!");
      return false;
    }
  };

  setToken = (idToken: string) => {
    localStorage.setItem("id_token", idToken);
  };

  getToken = () => {
    return localStorage.getItem("id_token");
  };

  logout = () => {
    localStorage.removeItem("id_token");
  };

  getConfirm = () => {
    const token = this.getToken();
    if (token) {
      return decode(token);
    } else {
      // console.log("token confirmation failed!");
      return null;
    }
  };

  fetch = (url: string, options: any) => {
    const headers: any = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    if (this.loggedIn()) {
      headers["Authorization"] = "Bearer " + this.getToken();
    }

    return fetch(url, {
      headers,
      ...options,
    })
      .then((res) => this._checkStatus(res, url, options))
      .catch((err) => reportToSentry(err));
  };

  _checkStatus = (response: any, url: string, body: any) => {
    if (response.status >= 200 && response.status < 300) {
      return response;
    } else {
      reportToSentry(
        new Error(
          "Reponse code " +
          response.status +
          " received from server. Request URL: '" +
          url +
          "'."
        )
      );
      window.location.href = "/login";
    }
  };
}
