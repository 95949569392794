import AuthHelperMethods from "../util/AuthHelperMethods";
import { User } from "../../../common";

const Auth = new AuthHelperMethods();

export const getUsers = (): Promise<User[]> => {
  return Auth.fetch("/api/users", {})
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
};

export const patchUser = (
  firstName: string,
  lastName: string,
  email: string,
  iban: string,
  password: string,
  badgeNumber: string,
  badgeExpiration: Date
) => {
  const body: object = {
    firstName,
    lastName,
    email,
    iban,
    password,
    badgeNumber,
    badgeExpiration
  };
  return Auth.fetch("/api/users/", {
    method: "PATCH",
    body: JSON.stringify(body),
  });
};

export const patchOtherUser = (
  id: string,
  firstName: string,
  lastName: string,
  email: string,
  iban: string,
  password: string,
  level: string,
  username: string,
  badgeNumber: string,
  badgeExpiration: Date
) => {
  const body: object = {
    firstName,
    lastName,
    email,
    iban,
    password,
    level,
    username,
    badgeNumber,
    badgeExpiration
  };
  return Auth.fetch("/api/users/" + id, {
    method: "PATCH",
    body: JSON.stringify(body),
  });
};

export const newUser = (
  firstName: string,
  lastName: string,
  email: string,
  iban: string,
  password: string,
  level: string,
  username: string,
  badgeNumber: string,
  badgeExpiration: Date
) => {
  const body: object = {
    username,
    firstName,
    lastName,
    email,
    iban,
    password,
    level,
    badgeNumber,
    badgeExpiration
  };
  return Auth.fetch("/api/users", {
    method: "POST",
    body: JSON.stringify(body),
  });
};

export const removeUser = (id: string): Promise<void> => {
  return Auth.fetch("/api/users/" + id, {
    method: "DELETE",
  });
};
