import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  button: {
    width: "50px",
    padding: "10px",
    backgroundColor: "lightgray",
    borderRadius: "5px",
    textAlign: "center",
    fontWeight: "bold",
    cursor: "pointer"
  }
}));

const EasyTapButton = (props: any) => {
  const classes = useStyles();
  return (
    <div onClick={props.onClick} className={classes.button}>
      {props.label}
    </div>
  );
};

export default EasyTapButton;
